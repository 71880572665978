import styled from '@emotion/styled';
import { green } from '@mui/material/colors';

import _ from 'lodash';
import React, { MouseEvent, memo, useCallback, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  ZAxis
} from 'recharts';
import { useRecoilState } from 'recoil';
import { ChartType, DrillFeature, Feature, Operation, } from '../../models/feature';
import { hoveredFeatureState } from '../../recoils/feature';

import OperationIcon from '../common/OperationIcon';
import { MemoizedChart } from './Chart';


const ElementWrapper = styled.div`
position: relative;
width: 100%;
height: 100%;

z-index: 1;
`;

const Element = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
padding: 0px;

font-size: 14px;

border-radius: 4px;

background-color: #ffffff;

z-index: 6;

&.hovered {
  z-index: 15;
}
`;

const ElementShadow = styled.div<{
  color?: string,
  hoverColor?: string,
}>`
position: absolute;

top: 0px;
left: 0px;

width: 100%;
height: 100%;

border-radius: 4px;
box-shadow: 0px 1px 5px rgba(0,0,0,.5);
background-color: #ffffff;

opacity: 0.8;

z-index: 5;

transition: opacity .15s, transform .15s, background-color .15s;

*:hover > &,
*.reader > &,
*.selected > & {
  opacity: 1;
  background-color: ${({hoverColor}) => hoverColor || '#bbbbbb'};
  transform: scale(1.02679, 1.02449);
}
*.selected > & {
  background-color: ${green[600]};
}
*.reader > & {
  background-color: ${({color}) => color || green[600]};
}
`;

// 224 x 245
const ElementChildRepresentative = styled.div<{
  color?: string,
  hoverColor?: string,
}>`
position: absolute;

top: 3px;
left: 3px;

width: 100%;
height: 100%;

border-radius: 4px;
background-color: #ffffff;

transition: background-color .15s;

z-index: 4;

*:hover > & {
  background-color: ${({hoverColor}) => hoverColor || '#bbbbbb'};
}
*.selected > & {
  background-color: ${green[600]};
}
*.reader > & {
  background-color: ${({color}) => color || green[600]};
}
`;

const ElementChildRepresentativeShadow = styled(ElementShadow)`
top: 3px;
left: 3px;
z-index: 3;
`;

const ElementChildRepresentative2Depth = styled(ElementChildRepresentative)`
top: 6px;
left: 6px;
z-index: 2;
`
const ElementChildRepresentative2DepthShadow = styled(ElementShadow)`
top: 6px;
left: 6px;
z-index: 1;
`;


const CardDescriptionSection = styled.div<{bgColor?: string}>`
display: flex;
flex-direction: column;

width: 100%;
flex: 1 0 0px;
padding: 6px 8px;
border-top: 1px solid #dddddd;

.reader > * > & {
  background-color: ${({bgColor}) => bgColor || 'transparent'}
}
`;

const ChartTitleWrapper = styled.div`
display: flex;
width: 100%;
height: 24px;
`;

const ChartTitle = styled.div`
flex: 1 0 0px;
font-size: 15px;
font-weight: 500;
line-height: 24px;
color: #333333;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

const ChartDepthBadge = styled.div<{borderColor?: string}>`
width: 20px;
height: 20px;
padding: 0px 0px 0px 0px;
margin: 2px 0px 2px 4px;

font-size: 12px;
font-weight: 500;
line-height: 18px;
text-align: center;

background-color: rgba(255, 255, 255, .5);
border: 1px solid ${({borderColor}) => borderColor};
border-radius: 4px;
`;

const ChartDescriptionWrapper = styled.div`
width: 100%;
height: 16px;

font-size: 12px;
font-weight: 400;
line-height: 16px;
color: rgba(0, 0, 0, .6);

overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const ChartNoDescription = styled.div`
color: rgba(0, 0, 0, .4);
`

const ChartOperationWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
height: 28px;
`;

const ChartOperationBadge = styled.div`
position: relative;

width: 16px;
height: 16px;
padding: 1px;
margin: 0px 8px 0px 0px;

border-radius: 2px;
background-color: #bbbbbb;

& path: #ffffff !important;

&::before {
  position: absolute;
  display: block;
  content: '';

  top: 7px;
  left: 7px;

  width: 2px;
  height: 2px;
  
  border-radius: 2px;
  background-color: #ffffff;
}

.active > & {
  background-color: #107CC7;
}
.active > &::before {
  display: none;
}
`;

const ChartOperandDescription = styled.div`
flex: 1 0 0px;

font-size: 12px;
font-weight: 400;
line-height: 16px;
color: rgba(0, 0, 0, .4);

overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;

.active > & {
  color: rgba(0, 0, 0, .8);
}
`;

const ChartContainer = styled.div`
flex: 0 0 180px;
width: 100%;

border-radius: 4px 4px 0px 0px;

.selected > * > & {
  background-color: #EDF6ED;
}
`;

interface ChartProps {
  borderColor?: string
  hoverBorderColor?: string
  bgColor?: string
  endBorderColor?: string
  endBgColor?: string
  feature: DrillFeature
  onClick?: () => void
  onContextMenu?: () => void
}


const ChartElement = ({
  borderColor,
  hoverBorderColor,
  bgColor,
  endBorderColor,
  endBgColor,
  feature,
  onClick,
  onContextMenu,
}: ChartProps) => {

  const [
    hoveredFeature,
    setHoveredFeature
  ] = useRecoilState<Feature|undefined>(hoveredFeatureState)

  const onChartClick = (evt: MouseEvent) => {
    if (onClick) { onClick() }
  };

  const onChartRightClick = (evt: MouseEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (onContextMenu) { onContextMenu() }
  }

  const onMouseEnter = useCallback((feature: Feature) => {
    setHoveredFeature(feature);
  }, [hoveredFeature, setHoveredFeature]);

  const onMouseLeave = useCallback(() => {
    setHoveredFeature(undefined)
  }, [hoveredFeature, setHoveredFeature])

  const hasChild = !_.isEmpty(feature.operands);
  const hasGrandChild = (_.isArray(feature.operands) &&
                         feature.operands.length > 0 &&
                         !_.isEmpty(feature.operands[0].operands))

  const hoverColor = hasChild ? hoverBorderColor : undefined
  return (
    <ElementWrapper
      className={[
        borderColor ? 'reader' : '',
      ].join(' ')}
    >
      <Element
        className={[
          hoveredFeature && hoveredFeature.id === feature.id ? 'hovered' : '',
        ].join(' ')}
        onMouseEnter={() => onMouseEnter(feature)}
        onMouseLeave={onMouseLeave}
        onClick={onChartClick}
        onContextMenu={onChartRightClick}
      >
        <ChartContainer>
          <MemoizedChart feature={feature}/>
        </ChartContainer>
        <CardDescriptionSection bgColor={bgColor}>
          <ChartTitleWrapper>
            <ChartTitle>
              {feature.name}
            </ChartTitle>
            {feature.drillInfo && feature.drillInfo.depth > 0 &&
              <ChartDepthBadge borderColor={borderColor}>
              {feature.drillInfo.depth}
              </ChartDepthBadge>
            }
          </ChartTitleWrapper>
          <ChartDescriptionWrapper>
            {feature.description || <ChartNoDescription>No description</ChartNoDescription>}
          </ChartDescriptionWrapper>
          <ChartOperationWrapper className={_.isUndefined(feature.operation) ? '' : 'active'}>
            <ChartOperationBadge>
              <OperationIcon operation={feature.operation}/>
            </ChartOperationBadge>
            <ChartOperandDescription>
              {
                _.isUndefined(feature.operands) ? 'No data' :
                _.join(_.map(feature.operands, 'name'), ', ') || 'No data'
              }
            </ChartOperandDescription>
          </ChartOperationWrapper>
        </CardDescriptionSection>
      </Element>
      <ElementShadow hoverColor={hoverColor}  color={borderColor}/>
      {hasChild &&
        <React.Fragment>
          <ElementChildRepresentative hoverColor={hoverColor} color={borderColor}/>
          <ElementChildRepresentativeShadow hoverColor={hoverColor} color={borderColor}/>
        </React.Fragment>
      }
      {hasGrandChild &&
        <React.Fragment>
          <ElementChildRepresentative2Depth hoverColor={hoverColor}  color={borderColor}/>
          <ElementChildRepresentative2DepthShadow hoverColor={hoverColor}  color={borderColor}/>
        </React.Fragment>
      }
    </ElementWrapper>
  )
};

export default ChartElement;
