import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';

import _ from 'lodash';
import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import { chartFilterState, datasetFeaturesState, featuresState } from '../../recoils/feature';
import { ChartFilter, Feature } from '../../models/feature';

import ChartElement from '../../components/charts/Chart';
import { useNavigate } from 'react-router-dom';


const Section = styled.div`
display: flex;
flex: 1 0 0px;
width: 100%;
flex-direction: column;
padding: 16px 0px 0px 24px;
`;

const ToolbarSection = styled.div`
display: flex;
flex: 0 0 41px;
width: 100%;
height: 41px;

border-bottom: 1px solid #cccccc;
`;

const SearchResultText = styled.div`
font-size: 18px;
font-weight: 600;
line-height: 24px;

padding: 8px 0px;

color: #333333;
`;

const AddChartButton = styled.button`
width: 180px;
height: 40px;

padding: 8px 16px;
margin: 0px 24px 0px auto;

font-size: 16px;
linee-height: 24px;
color: white;

border: none;
border-radius: 4px 4px 0px 0px;
background-color: ${blue[600]};

cursor: pointer;
`;

const ResultSection = styled.div`
display: flex;
flex: 1 0 0px;
width: 100%;
`;


const ChartNameListWrapper = styled.div`
display: flex;
flex-direction: column;

flex: 0 0 336px;
width: 336px;
height: 100%;

padding: 12px 0px;

border-right: 1px solid #dddddd;
`;

const ChartNameRow = styled.div`
flex: 0 0 32px;
width: 100%;
height: 32px;

padding: 4px 8px;

font-size: 14px;
font-weight: 400;
line-height: 24px;

text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;

color: #333333;
border-radius: 4px 0px 0px 4px;

cursor: pointer;

&:hover {
  font-weight: 600;
  background-color: #f3f3f3;
}
`;

const ChartGridWrapper = styled.div`
display: flex;
flex-wrap: wrap;
flex: 1 0 0px;
height: 100%;
align-content: flex-start;
padding: 0px 0px 20px 20px;
`;

const ChartContainer = styled.div`
position: relative;
width: 224px;
height: 261px;

margin: 20px 20px 0px 0px;

z-index: 1;

&:hover {
  z-index: 2;
}
`;


const ChartSection = () => {
  const navigate = useNavigate();

  const [features, setFeatures] = useRecoilState<Feature[]>(featuresState);
  const chartFilter = useRecoilValue<ChartFilter[]>(chartFilterState);
  const datasetFeatures = useRecoilValue<Feature[]>(datasetFeaturesState);

  const filter = {} as any;
  _.each(chartFilter, (cf) => {
    filter[cf.name] = cf.value;
  });
  const filteredFeatures = _.filter(_.cloneDeep(datasetFeatures), (datasetFeature) => {
    return _.isMatch(datasetFeature.attribute || {}, filter);
  });
  _.each(filteredFeatures, (filteredFeature, idx) => {
    filteredFeature.chart.gridInfo.x = (idx % 5);
  })

  const addCharts = () => {
    setFeatures(_.cloneDeep(_.uniqBy(features.concat(filteredFeatures), 'id')));
    navigate('/');
  };

  return (
    <Section>
      <ToolbarSection>
        <SearchResultText>Found {filteredFeatures.length} results</SearchResultText>
        <AddChartButton
          onClick={addCharts}
        >Add {filteredFeatures.length} Charts</AddChartButton>
      </ToolbarSection>
      <ResultSection>
        <ChartNameListWrapper>
          {
            _.map(filteredFeatures, (feature) => {
              return (
                <ChartNameRow key={feature.id}>{feature.name}</ChartNameRow>
              )
            })
          }
        </ChartNameListWrapper>
        <ChartGridWrapper>
          {
            _.map(filteredFeatures, (feature) => {
              return (
                <ChartContainer key={feature.id}>
                  <ChartElement feature={feature} selectable={false}/>
                </ChartContainer>
              )
            })
          }
        </ChartGridWrapper>
      </ResultSection>
    </Section>
  );
};

export default ChartSection;
