import styled from '@emotion/styled';
import { green } from '@mui/material/colors';

import _ from 'lodash';
import React, { useCallback } from 'react';
import GridLayout from 'react-grid-layout';

import Chart from './Chart';

import { Feature } from '../../models/feature';


const Wrapper = styled.div`
position: relative;
width: 100%;
`;

const CustomGridLayout = styled(GridLayout)`
& > div.react-grid-placeholder {
  border-radius: 4px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23AAAAAAFF' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  z-index: 0;
}
& > .react-grid-item {
  transition: transform .2s;
}
& > .react-grid-item:hover {
  z-index: 2;
}
`;

const ChartDiv = styled.div`
width: 200px;
height: 100px;

border-radius: 4px;

cursor: pointer;

z-index: 1;
&.resizing {
  z-index: 100;
}

&.react-draggable-dragging {
  background-color: ${green[50]};
  z-index: 100;
  transition: none !important;
}

& > span.react-resizable-handle {
  display: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 16px;
  height: 16px;
}
&:hover > span.react-resizable-handle::before,
&.react-draggable-dragging > span.react-resizable-handle::before {
  position: absolute;
  display: block;
  content: '';
  right: 4px;
  bottom: 4px;
  width: 8px;
  height: 8px;

  border: 2px solid #999999;
  border-width: 0px 2px 2px 0px;

  z-index: 3;
}
`;

interface ChartSectionProps {
  features: Feature[]
  setFeatures?: (newFeatures: Feature[]) => void
  isEditable?: boolean
}

const ChartSection = ({
  features,
  setFeatures,
  isEditable = false,
}: ChartSectionProps) => {
  const layout = _.map(features, (feature) => {
    return {i: feature.id, ...feature.chart.gridInfo}
  })

  const onLayoutChange = useCallback((newLayout: any) => {
    const chartInfoMaps = {} as any
    _.each(newLayout, (layoutItem: any) => {
      chartInfoMaps[layoutItem.i] = layoutItem
    })

    const newFeatures = _.cloneDeep(features)
    _.each(newFeatures, (newFeature: Feature) => {
      const chartInfo = chartInfoMaps[newFeature.id]
      if (_.isUndefined(chartInfo)) {
        return;
      }
      newFeature.chart.gridInfo = _.pick(chartInfo, ['x', 'y', 'w', 'h'])
    })
    if (_.isFunction(setFeatures)) {
      setFeatures(_.cloneDeep(newFeatures))
    }
  }, [features, setFeatures])

  const updateFeatures = (newFeature: Feature, index: number) => {
    if (_.isUndefined(setFeatures)) return

    const newFeatures = _.cloneDeep(features)
    newFeatures[index] = newFeature  
    return setFeatures(newFeatures)
  }

  return (
    <Wrapper>
      <CustomGridLayout
        layout={layout}
        cols={5}
        rowHeight={261}
        width={1272}
        margin={[28, 28]}
        containerPadding={[20, 20]}
        isResizable={false}
        isDraggable={isEditable}
        onLayoutChange={onLayoutChange}
      >
        {_.map(features, (feature, index) => {
          return (
            <ChartDiv key={feature.id}>
              <Chart
                feature={feature}
                selectable={isEditable}
                highlightWhenCreated={isEditable}
                updateFeature={(newFeature: Feature) => updateFeatures(newFeature, index)}
              />
            </ChartDiv>
          )
        })}
      </CustomGridLayout>
    </Wrapper>
  )
}

export default ChartSection
