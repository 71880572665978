import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { drillFeaturesState, loadedBoardNameState, selectedBoardNameState } from '../../recoils/feature';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { Board, DrillFeature, Feature } from '../../models/feature';

import { DRILLBOARD_WORKER_URL } from '../../utils/constants';

import _ from 'lodash';

const Wrapper = styled.div`
width: 100%;
padding: 16px 16px 0px 16px;
`;

const CustomTextField = styled(TextField)`
font-size: 14px;
margin: 0px 0px 12px;

& > .MuiInputLabel-root {
  transition: none;
}
& > .MuiInputBase-root > .MuiInputBase-input {
  width: 253px;
  padding: 9px 32px 9px 11px;
  font-size: 14px;
  line-height: 22px;
}
& > .MuiInputBase-root:hover > .MuiOutlinedInput-notchedOutline {
  border-color: #666666;
}
`;

const CustomMenuItem = styled(MenuItem)`
font-size: 14px;
`

const setDefaultDrillInfoAtFeatures = (features: Feature[], rootId?: string, path: string = '', depth: number = 0) => {
  return _.map(features, (feature, index) => {
    const newFeature = _.cloneDeep({
      ...feature,
      drillInfo: {
        isVisible: depth === 0,
        depth,
        path: path ? `${path}.operands[${index}]` : `[${index}]`,
      }
    })
    if (!_.isUndefined(newFeature.operands)) {
      newFeature.operands = setDefaultDrillInfoAtFeatures(
        newFeature.operands, rootId || feature.id, newFeature.drillInfo.path,
        depth + 1)
    }
    return newFeature as DrillFeature
  })
}

const listFromStorageAsync = async (): Promise<string[]> => {
  const response = await axios.get(DRILLBOARD_WORKER_URL)
  const objectLists = response.data
  return objectLists
}

const boardFromStorageAsync = async (boardName: string): Promise<Board> => {
  const response = await axios.get(`${DRILLBOARD_WORKER_URL}${boardName}`)
  console.log(response.data)
  return response.data as Board
}

const PresetSelector = () => {
  const setDrillFeaturesState = useSetRecoilState<DrillFeature[]>(drillFeaturesState);
  const [boardName, setBoardName] = useRecoilState<string>(selectedBoardNameState)
  const [loadedBoardName, setLoadedBoardName] = useRecoilState<string>(loadedBoardNameState)

  const [boardNameList, setBoardNameList] = useState<string[]>([])

  useEffect(() => {
    const updateBoardNameListAsync = async () => {
      // const boardNameList = await listFromStorageAsync()
      const boardNameList = ["G7_GDP_per_capita.json", "NCHZ/NBUO EXPERT.json"]
      setBoardNameList(boardNameList)
    }
    updateBoardNameListAsync()
  }, []);

  useMemo(() => {
    const updateBoardAsync = async () => {
      if (!_.isString(boardName)) return
      if (boardName === loadedBoardName) return

      console.log(boardName, loadedBoardName)

      const board = await boardFromStorageAsync(boardName)
      const features = board.features

      setLoadedBoardName(boardName)
      setDrillFeaturesState(setDefaultDrillInfoAtFeatures(features))
    }
    updateBoardAsync()
  }, [boardName])

  return (
    <Wrapper>
      <CustomTextField select
                       label="Knowledge Level"
                       value="EXPERT"
                       disabled={true}>
        <CustomMenuItem value="ALL">ALL</CustomMenuItem>
        <CustomMenuItem value="EXPERT">EXPERT</CustomMenuItem>
        <CustomMenuItem value="NOVICE">NOVICE</CustomMenuItem>
      </CustomTextField>
      <CustomTextField select
                      label="Preset"
                      value={boardName}
                      onChange={(e) => setBoardName(e.target.value)}>
        {_.map(boardNameList, (boardName) => {
          return (
            <CustomMenuItem value={boardName} key={boardName}>
              {boardName}
            </CustomMenuItem>
          )
        })}
      </CustomTextField>
      
    </Wrapper>
  )
}

export default PresetSelector;
