import React from 'react'
import styled from '@emotion/styled'

import {ReactComponent as TreeHideIconSvg} from '../../assets/tree-hide-icon.svg';
import {ReactComponent as TreePlusIconSvg} from '../../assets/tree-plus-icon.svg';
import {ReactComponent as TreeMinusIconSvg} from '../../assets/tree-minus-icon.svg';
import {ReactComponent as TreeDivideIconSvg} from '../../assets/tree-divide-icon.svg';
import {ReactComponent as TreeMeanIconSvg} from '../../assets/tree-mean-icon.svg';
import {ReactComponent as TreeMergeIconSvg} from '../../assets/tree-merge-icon.svg';
import {ReactComponent as TreeMerge2yIconSvg} from '../../assets/tree-merge-2y-icon.svg';
import {ReactComponent as TreeScatterIconSvg} from '../../assets/tree-scatter-icon.svg';
import { Operation } from '../../models/feature';

const NoneOperationIcon = styled.div<{margin?: number}>`
width: 2px;
height: 2px;

margin: ${({margin}) => margin || 6}px;
border-radius: 2px;

background-color: white;
.depth-0 > .visible & {
  background-color: #666666;
}
`;

interface OperationIconProps {
  operation?: Operation
  margin?: number
}

const OperationIcon = ({operation, margin}: OperationIconProps) => {
  switch (operation) {
    case Operation.ADD:
      return <TreePlusIconSvg/>
    case Operation.SUBTRACT:
      return <TreeMinusIconSvg/>
    case Operation.MEAN:
      return <TreeMeanIconSvg/>
    case Operation.SCATTER:
      return <TreeScatterIconSvg/>
    case Operation.MERGE_1_AXIS:
      return <TreeMergeIconSvg/>
    case Operation.MERGE_2_AXIS:
      return <TreeMerge2yIconSvg/>
    case Operation.HIDE:
      return <TreeHideIconSvg/>
    case Operation.DIVIDE:
      return <TreeDivideIconSvg/>
  }

  return <NoneOperationIcon margin={margin}/>

}

export default OperationIcon
