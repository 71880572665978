import _ from 'lodash';
import { atom } from 'recoil';
import { ChartFilter, DrillFeature, Feature } from '../models/feature';


const DEFAULT_SELECTED_FEATURE_IDS = [] as string[];
const SELECTED_FEATURE_IDS_KEY = 'selected-feature-ids-key';

export const selectedFeatureIdsState = atom<string[]>({
    key: SELECTED_FEATURE_IDS_KEY,
    default: _.cloneDeep(DEFAULT_SELECTED_FEATURE_IDS)
});


const DEFAULT_FEATURES = [] as Feature[];
const FEATURES_STATE_KEY = 'features-state-key';

export const featuresState = atom<Feature[]>({
    key: FEATURES_STATE_KEY,
    default: _.cloneDeep(DEFAULT_FEATURES)
})


const HOVERED_FEATURE_KEY = 'hovered-feature-key';

export const hoveredFeatureState = atom<Feature | undefined>({
    key: HOVERED_FEATURE_KEY,
    default: undefined,
});

const DEFAULT_DRILLDOWN_FEATURES = [] as DrillFeature[];
const DRILLDOWN_FEATURES_STATE_KEY = 'drilldown-features-state-key';

export const drillFeaturesState = atom<DrillFeature[]>({
    key: DRILLDOWN_FEATURES_STATE_KEY,
    default: _.cloneDeep(DEFAULT_DRILLDOWN_FEATURES)
})


const DEFAULT_CHART_FILTER = [] as ChartFilter[];
const CHART_FILTER_STATE_KEY = 'chart-filter-state-key'

export const chartFilterState = atom<ChartFilter[]>({
    key: CHART_FILTER_STATE_KEY,
    default: _.cloneDeep(DEFAULT_CHART_FILTER),
});


const DEFAULT_DATASET_FEATURES = [] as Feature[];
const DATASET_FEATURES_STATE_KEY = 'dataset-features-state-key';

export const datasetFeaturesState = atom<Feature[]>({
    key: DATASET_FEATURES_STATE_KEY,
    default: _.cloneDeep(DEFAULT_DATASET_FEATURES)
});


export const selectedBoardNameState = atom<string>({
    key: 'selected-board-name-state-key',
    default: '-'
})

export const loadedBoardNameState = atom<string>({
    key: 'loaded-board-name-state-key',
    default: '-',
})
