import React from 'react';

import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MouseLeftButtonIconSvg } from '../../assets/mouse-left-button-icon.svg';
import { ReactComponent as MouseRightButtonIconSvg } from '../../assets/mouse-right-button-icon.svg';

import _ from 'lodash';

const Wrapper = styled.div`
position: sticky;
display: flex;
align-items: center;

width: 100%;
height: 56px;

background-color: #333333;
box-shadow: 0px 1px 3px rgba(0, 0, 0, .3);

z-index: 999;
`;

const Section = styled.div`
display: flex;
align-items: center;

width: 1600px;
height: 56px;
padding: 0px 16px;
margin: 0 auto;
`;

const Title = styled.div`
margin: 0px 120px 0px 0px;
font-size: 24px;
font-weight: 500;
line-height: 32px;
color: #ffffff;
`;

const NavWrapper = styled.div`
display: flex;
`;

const NavItem = styled.div`
width: 120px;
height: 56px;

padding: 16px 0px;

font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;

color: #999999;

cursor: pointer;

&:hover {
  color: #dddddd;
  background-color: rgba(255, 255, 255, .1);
}

&.active {
  color: #ffffff;

  padding-bottom: 13px;
  border-bottom: 3px solid #ffffff;
}
`;

const ReaderBoardHelper = styled.div`
display: flex;
justify-content: center;

height: 40px;

padding: 9px 11px;
margin: 0px 4px 0px auto;

font-size: 14px;
font-weight: 400;
line-height: 20px;

color: #eeeeee;

border: 1px solid #666666;
border-radius: 4px;

text-wrap: nowrap;

background-color: transparent;

& > svg {
  margin: 0px 6px 0px 0px;
}
`;

const Separator = styled.div`
width: 1px;
height: 16px;
margin: 2px 10px;

background-color: #666666;
`;

export enum HeaderModeType {
  AUTHOR,
  READER,
}

interface HeaderProps {
  mode: HeaderModeType
}

const NAV_ITEMS = [
  {
    title: 'AUTHOR',
    mode: HeaderModeType.AUTHOR,
    url: '/'
  },
  {
    title: 'READER',
    mode: HeaderModeType.READER,
    url: '/reader'
  },
]

const Header = ({mode}: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Section>
        <Title>Drillboards</Title>
        <NavWrapper>
          {
            _.map(NAV_ITEMS, (item, idx) => {
              return (
                <NavItem className={mode === item.mode ? 'active' : ''}
                         onClick={() => navigate(item.url)}
                         key={idx}
                >
                  {item.title}
                </NavItem>
              )
            })
          }
        </NavWrapper>
        {mode === HeaderModeType.READER && <ReaderBoardHelper>
          <MouseLeftButtonIconSvg/> Drill Down
          <Separator/>
          <MouseRightButtonIconSvg/> Roll Up
        </ReaderBoardHelper>}
      </Section>
    </Wrapper>
  )
};

export default Header;
