import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import crypto from "crypto";
import React, { useEffect, useState } from 'react';
import { readRemoteFile, jsonToCSV } from 'react-papaparse';

import VisualSearch from '../../components/visual-search/Component/VisualSearch';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { chartFilterState, datasetFeaturesState } from '../../recoils/feature';
import { ChartFilter, ChartType, Feature } from '../../models/feature';

const Section = styled.div`
display: flex;
width: 100%;
flex-direction: row;
padding: 24px 24px;

border-bottom: 1px solid #cccccc;
`;

const DatasetWrapper = styled.div`
display: flex;
flex-direction: column;
width: 336px;
flex: 0 0 336px;
`;

const DatasetFilter = styled.div`
width: 100%;
height: 56px;

padding: 7px 15px;

font-size: 16px;
font-weight: 600;
line-height: 40px;

color: #333333;

border: 1px solid #cccccc;
border-radius: 4px;
`;

const CustomTextField = styled(TextField)`
font-size: 14px;

& > .MuiInputLabel-root {
  transition: none;
  transform: translate(14px, -9px) scale(0.75);
}
& > .MuiInputBase-root > .MuiInputBase-input {
  width: 292px;
  padding: 16px 32px 16px 12px;
  font-size: 16px;
  line-height: 24px;
}
& > .MuiInputBase-root:hover > .MuiOutlinedInput-notchedOutline {
  border-color: #666666;
}
`;

const FilterWrapper = styled.div`
display: flex;
flex-direction: column;

flex: 1 0 0px;
padding: 0px 0px 0px 16px;
`;

const FilterTitle = styled.div`
width: 100%;
height: 16px;
margin: 0px 0px 8px 0px;

font-size: 16px;
line-height: 16px;
color: #666666;
`;

const SearchWrapper = styled.div`
display: flex;
flex-direction: row;

height: 56px;
padding: 7px 7px;

border-radius: 4px;

border: 1px solid #cccccc;

& .visual_search.react_visual_search {
  display: flex;
}
`;

interface Dataset {
  name: string
  filename: string
  columnCount: number
}

// type DatasetKey = 'UWSD' | 'OECD' | 'KODO' | 'GDP7' | 'USRD'
type DatasetKey = 'UWSD' | 'GDP7'
type DatasetKeyToDataset = {[key in DatasetKey]: Dataset}

const DATASET_KEY_TO_DATASET: DatasetKeyToDataset = {
  'UWSD': {
    name: 'USDA Water-Sensor Dataset (2017)',
    filename: 'water_sensor_2017.csv',
    columnCount: 3,
  },
  // 'OECD': {
  //   name: 'OECD vs OCHA Dataset, Funds for Palestine',
  //   filename: 'funds_for_palestine_2008_2022.csv',
  //   columnCount: 2,
  // },
  // 'KODO': {
  //   name: 'Korea Docter Dataset',
  //   filename: 'korea_doctor_2010_2020.csv',
  //   columnCount: 5,
  // },
  'GDP7': {
    name: 'GDP per Capita, G7',
    filename: 'gdp_per_capita_g7.csv',
    columnCount: 2,
  },
  // 'USRD': {
  //   name: 'US Regional Demographics Dataset',
  //   filename: 'us_regional_demographic.csv',
  //   columnCount: 1,
  // }
}

const getRemoteCsvFileAsync = async (filename: string) => {
  return new Promise<any>((resolve, reject) => {
    readRemoteFile(`/data/${filename}`, {
      download: true,
      complete: resolve,
      error: reject
    })
  })
}

interface FilterCategoryOption {
  label: string
  value: string
}

interface FilterCategory {
  label: string
  name: string
  type: string
  options: FilterCategoryOption[]
}

const TitleSection = () => {
  const setChartFilterState = useSetRecoilState<ChartFilter[]>(chartFilterState)
  const setDatasetFeatures = useSetRecoilState<Feature[]>(datasetFeaturesState)

  const [datasetKey, setDatasetKey] = useState<string>('UWSD')
  const [filterItems, setFilterItems] = useState<FilterCategory[]>([])

  useEffect(() => {
    const readDatasetAndUpdateDatasetFeaturesAsync = async () => {
      const dataset = _.get(DATASET_KEY_TO_DATASET, datasetKey) as Dataset
      if (_.isUndefined(dataset)) return

      const results = await getRemoteCsvFileAsync(dataset.filename)
      
      const transposed = _.zip(...results.data) as string[][]
      const filterCandidates = transposed.slice(1, 1 + dataset.columnCount)

      const newFilterItems = _.map(filterCandidates, (candidateRow: string[]) => {
        const filterName = candidateRow[0]
        const values = _.compact(_.uniq(candidateRow.slice(1)))
        return {
          label: filterName,
          name: filterName,
          type: 'list',
          options: _.map(values, (value) => {
            return {label: value, value}
          })
        }
      })

      const nameIndex = 1 + dataset.columnCount

      const headerRow = results.data[0]
      const attributeKeys = headerRow.slice(1, nameIndex)
      const ticks = headerRow.slice(nameIndex + 1)

      const contentRows = results.data.slice(1)
      const newDatasetFeatures = _.map(contentRows, (contentRow) => {
        const name = contentRow[nameIndex]
        const attributeValues = contentRow.slice(1, nameIndex)
        const newFeature: Feature = {
          id: contentRow[0] || `FFPA-${crypto.randomBytes(5).toString('hex')}`,
          name,
          attribute: _.fromPairs(_.zip(attributeKeys, attributeValues)),
          description: _.compact(attributeValues).join(' / '),
          operands: [],
          chart: {
            data: [_.map(contentRow.slice(nameIndex + 1), _.toNumber)],
            ticks,
            labels: [name],
            yAxes: ['left'],
            gridInfo: {x: 0, y: 0, w: 1, h: 1},
            type: ChartType.LINE,
          }
        }
        return newFeature
      })

      setDatasetFeatures(newDatasetFeatures)
      setFilterItems(newFilterItems)
      setChartFilterState([])
    }
    readDatasetAndUpdateDatasetFeaturesAsync()
  }, [datasetKey, setDatasetKey])

  const onFilter = (filter: any) => setChartFilterState(filter);

  return (
    <Section>
      <DatasetWrapper>
        <FilterTitle>Dataset</FilterTitle>
        
        <CustomTextField select value={datasetKey} onChange={(evt) => setDatasetKey(evt.target.value)}>
          {_.map(_.toPairs(DATASET_KEY_TO_DATASET), ([datasetKey, dataset]: [string, Dataset]) => {
            return <MenuItem key={datasetKey} value={datasetKey}>
              {dataset.name}
            </MenuItem>
          })}
        </CustomTextField>
      </DatasetWrapper>
      <FilterWrapper>
        <FilterTitle>Filters</FilterTitle>
        <SearchWrapper>
          <VisualSearch
            className="react_visual_search"
            category={filterItems}
            onFilter={onFilter}
          />
        </SearchWrapper>
      </FilterWrapper>
    </Section>
  );
};

export default TitleSection;
