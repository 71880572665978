import React, {Component, useState, useEffect, useCallback, useRef} from 'react';
import {Overlay, Popover} from 'react-bootstrap';
import _ from 'lodash';


const InputList = (props) => {
  const [showInput, setShowInput] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);

  useEffect(() => {
    const data = props.data;
    if (!_.isUndefined(data.value)) {
      const selectedOption = _.find(data.options, {value: data.value});
      setInputValue(selectedOption.label || '');
      setShowInput(false);
    }
    if (!_.isNull(inputRef)) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const selectedOption = _.find(props.data.options, {label: inputValue});
    if (_.isUndefined(selectedOption)) {
      return;
    }
    const newFilter = {
      name: props.data.name,
      value: selectedOption.value
    }
    props.onUpdateFilter(_.cloneDeep(newFilter));
  }, [inputValue])

  useEffect(() => {
    setInputValue(props.data.value || '');
  }, [props.data])

  const onValueClick = () => {
    setShowInput(true);
    setShowOptions(true);

    if (!_.isNull(inputRef)) {
      inputRef.current.focus();
    }
  };

  const onOptionClick = (option) => {
    setInputValue(option.label || '');
    setShowInput(false);
    setShowOptions(false);
  };

  const setFirstOptionIfInputIsNotSet = () => {
    const options = props.data.options;
    if (!_.isArray(options) || _.isEmpty(options)) {
      return
    }
    const selectedOption = _.find(options, {label: inputValue});
    if (!_.isUndefined(selectedOption)) {
      return;
    }
    setInputValue(options[0].label);
    setShowInput(false);
    setShowOptions(false);
  };

  const onChange = (evt) => {
    setInputValue(evt.target.value);
  };

  const onFocus = useCallback(() => {
    setShowOptions(true);
  }, [showOptions, setShowOptions]);

  const onBlur = () => {
    setFirstOptionIfInputIsNotSet();
    setShowOptions(false);
  };

  return (
    <React.Fragment>
      <div className="input_list_container" ref={inputContainerRef}>
        {!showInput &&
          <span className="search-value" onClick={onValueClick}>{inputValue || ""}</span>
        }
        <input
          key="input_list"
          ref={inputRef}
          className={[
            "input_text_value",
            showInput ? "focused" : ""
          ].join(' ')}
          value={inputValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {
        showOptions &&
        <Overlay
          key="overlay"
          show={showOptions}
          target={() => inputContainerRef.current}
          placement="bottom-start"
          >
          <Popover id="input_list_options" className="popover_options">
            <div className="list_wrapper">
              {
                _.isArray(props.data.options) &&
                props.data.options.map((obj, i)=>{
                  return (
                    <span
                      key={i}
                      className="option"
                      onMouseDown={(_e) => onOptionClick(obj)}
                    >{obj.label}</span>
                  )
                })
              }
            </div>
          </Popover>
        </Overlay>
      }
    </React.Fragment>
  );
};

export default InputList;
