import _ from 'lodash';
import crypto from "crypto";
import { useRecoilState } from 'recoil';

import { ChartType, Feature, Operation } from '../../models/feature';
import { featuresState, selectedFeatureIdsState } from '../../recoils/feature';


export enum FeatureOperation {
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
  MEAN = "MEAN",
  DIVIDE = "DIVIDE",
  SCATTER = "SCATTER",
  MERGE_1_AXIS = "MERGE (1 Y-axis)",
  MERGE_2_AXIS = "MERGE (2 Y-axis)",
  HIDE = "HIDE",
  RENAME = "RENAME",
  SPLIT = "SPLIT",
  DELETE = "DELETE"
}


export const MAX_OPERAND_COUNT = 20;

const _getFeatureDataCount = (feature: Feature): number => {
  return feature.chart.data.length;
}

const _generateEmptyFeature = (): Feature => {
  return {
    id: crypto.randomBytes(8).toString('hex'),
    name: '',
    description: '',
    attribute: {},
    operands: [],
    chart: {
      type: ChartType.LINE,
      data: [],
      ticks: [],
      yAxes: [],
      labels: [],
      gridInfo: {
        x: -1,
        y: -1,
        w: 1,
        h: 1,
      }
    }
  }
}

type ArithmeticOperaion = Operation.ADD |
  Operation.SUBTRACT |
  Operation.DIVIDE |
  Operation.MEAN

const _runArithmeticOperation = (
  features: Feature[],
  operandIds: string[],
  operation: ArithmeticOperaion,
  props: NewFeatureProps,
): Feature[] => {
  const newFeatures: Feature[] = [];
  const resultFeature = _generateEmptyFeature();

  const newNames = [];
  const registerEmptyFeature = _.once((feature) => {
    newFeatures.unshift(resultFeature);
    resultFeature.chart.ticks = feature.chart.ticks;
    resultFeature.chart.gridInfo = feature.chart.gridInfo;
    resultFeature.chart.yAxes = ['left'];
    resultFeature.operation = operation;
  });

  const operands: Feature[] = [];

  for (const feature of features) {
    const operandIndex = _.indexOf(operandIds, feature.id);
    if (operandIndex < 0) {
      newFeatures.push(feature);
    } else {
      operands[operandIndex] = feature;
    }
  }

  const newDataStorage: {[key: string]: Array<number>} = {};

  for (const [index, feature] of operands.entries()) {
    registerEmptyFeature(feature);

    newNames.push(feature.name);
    if (_.isArray(resultFeature.operands)) {
      resultFeature.operands.push(feature);
    }
    if (_getFeatureDataCount(feature) !== 1) {
      return features;
    }

    _.each(_.zip(feature.chart.ticks, feature.chart.data[0]),
           ([tick, datum]) => {
      if (_.isUndefined(tick) || _.isUndefined(datum)) {
        return;
      }
      if (_.isUndefined(newDataStorage[tick])) {
        newDataStorage[tick] = new Array(operands.length).fill(0);
      }
      newDataStorage[tick][index] = datum;
    });
  }
  const newTicks = [] as string[];
  resultFeature.chart.data = [
    _.map(_.sortBy(_.toPairs(newDataStorage), 0), ([key, row]) => {
      newTicks.push(key)

      const summed = _.reduce(row, (a, b) => a + b, 0);
      switch(operation) {
        case Operation.ADD:
          return summed
        case Operation.SUBTRACT:
          return 2 * row[0] - summed
        case Operation.MEAN:
          return summed / row.length
        case Operation.DIVIDE:
          return row[0] / row[1]
      }
    })
  ];
  resultFeature.chart.ticks = newTicks

  const getChartName = (names: string[], operation: ArithmeticOperaion) => {
    switch (operation) {
      case Operation.ADD:
        return names.join(' + ')
      case Operation.SUBTRACT:
        return names.join(' - ')
      case Operation.MEAN:
        return `Mean(${names.join(', ')})`
      case Operation.DIVIDE:
        return `(${names.join(') / (')})`
    }
  }

  resultFeature.chart.labels = [props.name || getChartName(newNames, operation)]
  resultFeature.name = props.name || getChartName(newNames, operation)
  resultFeature.description = props.description

  return _.cloneDeep(newFeatures);
}

const _runMergeOperation = (
  features: Feature[],
  operandIds: string[],
  props: NewFeatureProps,
  operation: Operation,
): Feature[] => {
  const newFeatures: Feature[] = [];
  const resultFeature = _generateEmptyFeature();

  const registerEmptyFeature = _.once((feature) => {
    newFeatures.unshift(resultFeature);
    resultFeature.chart.ticks = feature.chart.ticks;
    resultFeature.chart.gridInfo = feature.chart.gridInfo;
    resultFeature.operation = operation
    resultFeature.chart.type = ChartType.LINE
    if (operation === Operation.SCATTER) {
      resultFeature.chart.type = ChartType.SCATTER
    }
  });

  const operands: Feature[] = [];
  for (const feature of features) {
    const operandIndex = _.indexOf(operandIds, feature.id);
    if (operandIndex < 0) {
      newFeatures.push(feature);
    } else {
      operands[operandIndex] = feature;
    }
  }

  for (const [index, feature] of operands.entries()) {

    registerEmptyFeature(feature);
    if (_.isArray(resultFeature.operands)) {
      resultFeature.operands.push(feature);
    }

    resultFeature.chart.data = resultFeature.chart.data.concat(
      feature.chart.data);
    resultFeature.chart.yAxes = resultFeature.chart.yAxes.concat(
      feature.chart.yAxes);
    resultFeature.chart.labels = resultFeature.chart.labels.concat(
      new Array(feature.chart.labels.length).fill(feature.name));
  }

  if (operation === Operation.MERGE_2_AXIS) {
    resultFeature.chart.yAxes[1] = 'right';
  }

  resultFeature.name = props.name
  resultFeature.description = props.description

  return _.cloneDeep(newFeatures);
}

const _runHideOperation = (
  features: Feature[],
  operandIds: string[],
  props: NewFeatureProps,
): Feature[] => {
  const newFeatures: Feature[] = [];
  const resultFeature = _generateEmptyFeature();

  const registerEmptyFeature = _.once((feature) => {
    newFeatures.unshift(resultFeature);
    resultFeature.name = props.name || feature.name
    resultFeature.description = props.description || feature.description
    resultFeature.chart = _.cloneDeep(feature.chart);
    resultFeature.operation = Operation.HIDE;
  });

  const operands: Feature[] = [];
  for (const feature of features) {
    const operandIndex = _.indexOf(operandIds, feature.id);
    if (operandIndex < 0) {
      newFeatures.push(feature);
    } else {
      operands[operandIndex] = feature;
    }
  }

  for (const feature of operands) {
    registerEmptyFeature(feature);
    if (_.isArray(resultFeature.operands)) {
      resultFeature.operands.push(feature);
    }
  }
  return _.cloneDeep(newFeatures);
}


const _applyOperationToFeature = (
  features: Feature[],
  operation: FeatureOperation,
  operandIds: string[],
  props: NewFeatureProps,
): Feature[] => {
  if (operandIds.length > MAX_OPERAND_COUNT) {
    alert(`Cannot operate more than ${MAX_OPERAND_COUNT} charts.`);
    return features;
  }
  let newFeatures: Feature[] = [];
  switch (operation) {
    case FeatureOperation.SPLIT:
      _.each(features, (feature) => {
        if (_.includes(operandIds, feature.id) && feature.operands) {
          newFeatures.push(...feature.operands);
        } else {
          newFeatures.push(feature);
        }
      });
      return newFeatures;

    case FeatureOperation.ADD:
      if (operandIds.length < 2) { break; }
      return _runArithmeticOperation(features, operandIds, Operation.ADD, props);

    case FeatureOperation.SUBTRACT:
      if (operandIds.length < 2) { break; }
      return _runArithmeticOperation(features, operandIds, Operation.SUBTRACT, props);

    case FeatureOperation.MEAN:
      if (operandIds.length < 2) { break; }
      return _runArithmeticOperation(features, operandIds, Operation.MEAN, props);

    case FeatureOperation.DIVIDE:
      if (operandIds.length !== 2) { break; }
      return _runArithmeticOperation(features, operandIds, Operation.DIVIDE, props);
      
    case FeatureOperation.MERGE_2_AXIS:
      if (operandIds.length !== 2) { break; }
      return _runMergeOperation(features, operandIds, props, Operation.MERGE_2_AXIS);

    case FeatureOperation.MERGE_1_AXIS:
      if (operandIds.length < 2) { break; }
      return _runMergeOperation(features, operandIds, props, Operation.MERGE_1_AXIS);

    case FeatureOperation.HIDE:
      if (operandIds.length < 2) { break; }
      return _runHideOperation(features, operandIds, props);

    case FeatureOperation.SCATTER:
      if (operandIds.length !== 2) { break; }
      return _runMergeOperation(features, operandIds, props, Operation.SCATTER);
    
    case FeatureOperation.DELETE:
      _.each(features, (feature) => {
        if (_.includes(operandIds, feature.id)) return
        newFeatures.push(feature);
      })
      return newFeatures
  }
  return features;
};

interface NewFeatureProps {
  name: string
  description: string
}

export function useFeatureManager() {
  const [_selFeatIds, setSelFeatIds] = useRecoilState<string[]>(selectedFeatureIdsState);
  const [features, setFeatures] = useRecoilState<Feature[]>(featuresState);

  const defaultProps = {name: '', description: ''}
  const applyOperationToFeature = (
    operation: FeatureOperation,
    operandIds: string[],
    props?: NewFeatureProps,
  ): void => {
    setFeatures(
      _applyOperationToFeature(features, operation, operandIds, props || defaultProps));
    setSelFeatIds(_.cloneDeep([]));
  };

  const applyOperationTemporally = (
    operation: FeatureOperation,
    operandIds: string[],
    props?: NewFeatureProps,
  ): Feature => {
    return _applyOperationToFeature(features, operation, operandIds, props || defaultProps)[0]
  }

  return [
    applyOperationToFeature,
    applyOperationTemporally,
  ] as  [
    (operation: FeatureOperation, operandIds: string[], props?: NewFeatureProps) => void,
    (operation: FeatureOperation, operandIds: string[], props?: NewFeatureProps) => Feature,
  ]
}
