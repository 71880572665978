import React from 'react';
import styled from '@emotion/styled';

import { Scrollbars } from 'react-custom-scrollbars-2';

import PresetSelector from './PresetSelector';
import DrillDownSection from './DrillDownSection';
import TreeViewSection from './TreeViewSection';


const SectionWrapper = styled.div`
display: flex;
width: 100%;
height: 100%;
flex-direction: column;

max-height: 100vh;

flex: 1 0 0px;
`;

const BoardSection = styled.div`
display: flex;
width: 100%;
flex: 1 0 0px;
`;

const SidebarSection = styled.div`
display: flex;
flex-direction: column;
flex: 1 0 0px;
`;


const ContentSection = styled.div`
flex: 0 0 1272px;

display: flex;
flex-direction: column;

background-color: #f0f0f0;
`;

const ReaderPage = () => {
  return (
    <SectionWrapper>
      <BoardSection>
        
        <SidebarSection>
          
          <PresetSelector/>
          <Scrollbars>
            <TreeViewSection/>
          </Scrollbars>
        </SidebarSection>
        <ContentSection>
          <Scrollbars>
            <DrillDownSection/>
          </Scrollbars>
        </ContentSection>
      </BoardSection>
    </SectionWrapper>
  )
};

export default ReaderPage;
