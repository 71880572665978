import styled from '@emotion/styled';
import { blue, deepPurple, green, orange, pink } from '@mui/material/colors';

import _ from 'lodash';
import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';

import DrillDownChart from '../../components/charts/DrillDownChart';

import { DrillFeature, Feature } from '../../models/feature';
import { drillFeaturesState } from '../../recoils/feature';
import { useRecoilState } from 'recoil';
import { colorMixer } from '../../utils/colors';


const Wrapper = styled.div`
position: relative;
width: 100%;
`;


const CardSection = styled.div``

const ANIMATION_TIME_IN_MILLIS = 500
const ANIMATION_TIME_IN_SEC = ANIMATION_TIME_IN_MILLIS / 1000

const CardElementWrapper = styled.div<{baseTransform: string, endTransform: string}>`
position: absolute;

border-radius: 4px;

width: 224px;
height: 261px;

cursor: pointer;

z-index: 1;

transform-origin: top left;
transition: transform ${ANIMATION_TIME_IN_SEC}s, opacity ${ANIMATION_TIME_IN_SEC}s;

&.key-frame {
  z-index: 2;
}
&:hover {
  z-index: 3;
}
&.hide {
  display: none !important;
}
`;

const CardContent = styled.div`
position: relative;

z-index: 2;

transition: opacity .3s, transform .3s;
.fade-out > & {
  opacity: 0;
  transform: translateY(-10px);
}
`;

const CardPlaceholder = styled.div`
position: absolute;

top: 0px;
left: 0px;

width: 224px;
height: 261px;

border-radius: 4px;

background-color: #e8f5e9;
z-index: 0;
opacity: 0;
`;

interface CardElementProps {
  children?: ReactNode
  baseTransform: string
  endTransform: string
  hideAfterTransform: boolean
  isMainFeature: boolean
  onClick?: () => void
}

const CardElement = ({
  children,
  baseTransform,
  endTransform,
  onClick,
  hideAfterTransform,
  isMainFeature,
}: CardElementProps) => {
  const [hide, setHide] = useState<boolean>(false)
  const [opacity, setOpacity] = useState<number>(0)
  const [transform, setTransform] = useState<string>(baseTransform)

  useEffect(() => {
    if (isMainFeature && hideAfterTransform) {
      setOpacity(0)
    } else if (!isMainFeature || hideAfterTransform) {
      setOpacity(1)
    } else {
      setOpacity(0)
      setTimeout(() => setOpacity(1), ANIMATION_TIME_IN_MILLIS)
    }
    setTransform(baseTransform)
    setHide(false)
    setTimeout(() => setTransform(endTransform), ANIMATION_TIME_IN_MILLIS)

    if (hideAfterTransform) {
      setTimeout(() => setHide(true), 2 * ANIMATION_TIME_IN_MILLIS)
    }
  }, [isMainFeature, baseTransform, endTransform, hideAfterTransform])

  return (
    hide ? null :
    <CardElementWrapper style={{transform, opacity}}
                        className={[
                          isMainFeature ? 'key-frame' : '',
                          hideAfterTransform ? 'hide-after-transform' : '',
                        ].join(' ')}
                        baseTransform={baseTransform}
                        endTransform={endTransform}
                        onClick={onClick}>
      <CardContent>
        {children}
      </CardContent>
    </CardElementWrapper>
  )
}


const CHART_PER_ROW = 5;

const getDisplayFeatures = (features: DrillFeature[], remainFeatureIds: string[]): DrillFeature[] => {
  const orderedFeatures = _.sortBy(features, ['chart.gridInfo.y',
                                              'chart.gridInfo.x']);
  const flattenDeepFeature = (feature: DrillFeature): DrillFeature[] => {
    const shouldRemainThisFeature = _.includes(remainFeatureIds, feature.id)
    if (feature.drillInfo.isVisible && !shouldRemainThisFeature) {
      return [feature]
    }
    const flattened: DrillFeature[] = []
    if (shouldRemainThisFeature) {
      flattened.push(feature)
    }
    const flattenedChildFeatures: DrillFeature[] = (
      _.reduce(feature.operands, (flattened, feature) => {
        return _.concat(flattened, flattenDeepFeature(feature))
      }, [] as DrillFeature[])
    )
    return _.concat(flattened, flattenedChildFeatures)
  }

  return _.flatten(_.map(orderedFeatures, flattenDeepFeature))
}

// const COLORS = [green, blue, orange, pink, deepPurple]
const COLORS = [green, orange, deepPurple]
const BORDER_COLORS = _.map(COLORS, (color) => color[600])
const BORDER_COLOR_TO_BASE_COLOR = _.reduce(COLORS, (output, color) => {
  output[color[600]] = [color[50], color[200]]
  return output
}, {} as {[color: string]: [string, string]})

const adjustColor = (rootNode: DrillFeature) => {
  const borderColor = rootNode.drillInfo.color
  if (!borderColor) return

  const [bgColor, baseColor] = BORDER_COLOR_TO_BASE_COLOR[borderColor]
  const getMaxDepth = (node: DrillFeature): number => {
    if (node.drillInfo.isVisible) return -1
    if (node.operands.length === 0) return node.drillInfo.depth

    const candidates = _.map(node.operands, getMaxDepth)
    candidates.push(node.drillInfo.depth)
    const depth = _.max(candidates)
    if (_.isUndefined(depth)) return -1
    return depth
  }

  const maxDepth = getMaxDepth(rootNode) + 1
  const nodes = [] as DrillFeature[]
  _.each(rootNode.operands, (operand) => nodes.push(operand))
  while (nodes) {
    const node = nodes.shift()
    if (_.isUndefined(node)) break

    const epsilon = 1e-5
    const ratio = (node.drillInfo.depth + epsilon - 1) / (maxDepth + epsilon - 1) 
    const adjustedBorderColor = colorMixer(baseColor, borderColor, ratio)
    node.drillInfo.color = adjustedBorderColor
    node.drillInfo.bgColor = bgColor

    if (!node.drillInfo.isVisible) {
      _.each(node.operands, (operand) => nodes.push(operand))
    }
  }
}


const DrillDownSection = () => {
  const [drillDownFeatures, setDrillDownFeatures] = useRecoilState<DrillFeature[]>(drillFeaturesState)

  // Main = Root / Sub = Child
  const [mainFeatureId, setMainFeatureId] = useState<string>('')
  const [subFeatureIds, setSubFeatureIds] = useState<string[]>([])

  const [prevented, setPrevented] = useState<boolean>(false)

  const setTimeLimit = (timeOutInMillis: number, method: (feature: DrillFeature) => void) => {
    return (feature: DrillFeature) => {
      if (prevented) return
      setPrevented(true)
      method(feature)
      setTimeout(() => setPrevented(false), timeOutInMillis)
    }
  }

  const remainFeatureIds = _.concat(mainFeatureId, subFeatureIds)
  const displayFeatures = getDisplayFeatures(drillDownFeatures, remainFeatureIds)

  const getNextDrillDownColor = (): string | undefined => {
    const selectedColors = _.compact(_.map(drillDownFeatures, 'drillInfo.color'))
    const remainedColors = _.difference(BORDER_COLORS, selectedColors)
    return remainedColors[0]
  }

  const totalAnimationTimeInMillis = ANIMATION_TIME_IN_MILLIS * 2 + 100;

  const drillDownChart = setTimeLimit(totalAnimationTimeInMillis, (feature: DrillFeature) => {
    // Ignore when feature is already invisible.
    if (!feature.drillInfo.isVisible) return
    // Ignore when feature is leaf node.
    if (feature.operands.length === 0) return

    let rootNode = undefined
    const newDrillDownFeatures = _.cloneDeep(drillDownFeatures)

    const path = feature.drillInfo.path
    if (feature.drillInfo.depth === 0) {
      // Clicked chart is the root node.
      const borderColor = getNextDrillDownColor()
      if (!_.isString(borderColor)) {
        return alert(`Maximum drilldownable root node is ${COLORS.length}`)
      }
      const bgColor = BORDER_COLOR_TO_BASE_COLOR[borderColor][0]

      rootNode = _.get(newDrillDownFeatures, path)
      rootNode.drillInfo.color = borderColor
      rootNode.drillInfo.bgColor = bgColor
    }

    const clickedFeature = _.get(newDrillDownFeatures, path) as DrillFeature
    clickedFeature.drillInfo.isVisible = false

    const newSubFeatureIds: string[] = []
    _.each(clickedFeature.operands, (operand: DrillFeature) => {
      operand.drillInfo.isVisible = true
      newSubFeatureIds.push(operand.id)
    })

    setMainFeatureId(clickedFeature.id)
    setSubFeatureIds(newSubFeatureIds)

    if (_.isUndefined(rootNode)) {
      const rootPath = path.split('.')[0]
      if (_.isUndefined(rootPath)) {
        return console.log('`path` of clicked feature is broken', feature)
      }
      rootNode = _.get(newDrillDownFeatures, rootPath)
    }
    if (_.isUndefined(rootNode)) {
      return console.log('`root node` of clicked feature is not found',
                         newDrillDownFeatures, feature)
    }
    adjustColor(rootNode)
    setDrillDownFeatures(newDrillDownFeatures)
  })

  const rollUpChart = setTimeLimit(totalAnimationTimeInMillis, (feature: DrillFeature) => {
    if (feature.drillInfo.depth === 0) return 
    const newDrillDownFeatures = _.cloneDeep(drillDownFeatures)

    const path = feature.drillInfo.path
    const parentPath = path.split('.').slice(0, -1).join('.')
    const parentNode = _.get(newDrillDownFeatures, parentPath) as DrillFeature

    parentNode.drillInfo.isVisible = true
    const nodes = [] as DrillFeature[]
    const newSubFeatureIds: string[] = []
    _.each(parentNode.operands, (operand) => {
      nodes.push(operand)
    })
    while (nodes) {
      const node = nodes.shift()
      if (_.isUndefined(node)) break

      if (node.drillInfo.isVisible) {
        newSubFeatureIds.push(node.id)
      }
      node.drillInfo.isVisible = false
      node.drillInfo.color = undefined
      node.drillInfo.bgColor = undefined
      _.forEachRight(node.operands, (operand) => nodes.unshift(operand))
    }

    if (parentNode.drillInfo.depth === 0) {
      parentNode.drillInfo.color = undefined
      parentNode.drillInfo.bgColor = undefined
    } else {
      const rootPath = path.split('.')[0]
      const rootNode = _.get(newDrillDownFeatures, rootPath)
      adjustColor(rootNode)
    }
    setMainFeatureId(parentNode.id)
    setSubFeatureIds(newSubFeatureIds)
    setDrillDownFeatures(newDrillDownFeatures)
  })

  const nextDrillDownColor = getNextDrillDownColor()

  /* DrillDownSection Arrangement Information
   * Horizontal margin between each card: 28px
   * Vertical margin between each card: 28px
   * Margin between card and container : 20px
   * Size of card: 224px * 261px
   */

  // const xOffset = 14 + (56 + 14) * col
  // const yOffset = 17 + (65 + 16) * row

  const index2translate = (index: number, fadeUp: boolean): string => {
    const row = Math.floor(index / CHART_PER_ROW)
    const col = index % CHART_PER_ROW
    const xOffset = 20 + (224 + 28) * col
    const yOffset = 20 + (261 + 28) * row

    const applyFadeUp = fadeUp ? 'translateY(-16px)' : ''

    return `translate(${xOffset}px, ${yOffset}px) ${applyFadeUp}`

  }

  const calculateTransform = (
    features: DrillFeature[],
  ): [DrillFeature, string, string][] => {
    let [startIndex, endIndex] = [0, 0]

    return _.map(features, (feature) => {
      const currentStartIndex = startIndex
      const currentEndIndex = endIndex
      if (_.includes(subFeatureIds, feature.id)) {
        if (feature.drillInfo.isVisible) {
          // Set animation of SUB Features when DRILL-DOWN
          endIndex += 1
          if (_.last(subFeatureIds) === feature.id) {
            startIndex += subFeatureIds.length
          }
        } else {
          // Set animation of SUB features when ROLL-UP
          if (_.last(subFeatureIds) === feature.id) {
            endIndex += 1
            startIndex += subFeatureIds.length
          }
        }
      } else if (feature.id !== mainFeatureId) {
        startIndex += 1
        endIndex += 1
      }

      return [
        feature,
        index2translate(
          currentStartIndex,
          feature.id === mainFeatureId),
        index2translate(
          currentEndIndex,
          feature.id === mainFeatureId && !feature.drillInfo.isVisible)
      ]
    })
  }

  const displayInfos = calculateTransform(displayFeatures)

  return (
    <Wrapper>
      <CardSection>
        {_.map(displayInfos, ([feature, startTransform, endTransform]) => {
          const onFeatureClick = (feature.operands.length ?
            () => drillDownChart(feature) :
            undefined)

          return (
            <CardElement key={feature.id}
                         onClick={onFeatureClick}
                         baseTransform={startTransform}
                         endTransform={endTransform}
                         hideAfterTransform={!feature.drillInfo.isVisible}
                         isMainFeature={feature.id === mainFeatureId}>
              <DrillDownChart borderColor={feature.drillInfo.color}
                              hoverBorderColor={nextDrillDownColor}
                              bgColor={feature.drillInfo.bgColor}
                              feature={feature}
                              onContextMenu={() => rollUpChart(feature)}/>
            </CardElement>
          )
        })}
      </CardSection>
      <CardPlaceholder/>
    </Wrapper>
  )
};

export default DrillDownSection;
