import styled from '@emotion/styled';
import _ from 'lodash';

import React from 'react';
import { useRecoilValue } from 'recoil';

import LastNodeSvg from '../../assets/last-node.svg';
import LastNodeWithChildSvg from '../../assets/last-node-with-child.svg';
import MainNodeWithChildSvg from '../../assets/main-node-with-child.svg';
import MiddleNodeSvg from '../../assets/middle-node.svg';
import MiddleNodeWithChildSvg from '../../assets/middle-node-with-child.svg';

import OperationIcon from '../../components/common/OperationIcon';

import { DrillFeature } from '../../models/feature';
import { drillFeaturesState } from '../../recoils/feature';

const Wrapper = styled.div`
width: 100%;
padding: 0px 12px 12px 8px;
`;

const RowWrapper = styled.div<{bgColor?: string}>`
position: relative;
padding: 0px 0px 0px 16px;

background-image: url(${MiddleNodeSvg});
background-repeat: repeat-y;

&.with-child {
  background-image: url(${MiddleNodeWithChildSvg});
  background-repeat: no-repeat;
}

border-radius: 8px;

&.depth-0 {
  background: none;
  background-color: ${({bgColor}) => bgColor || 'none'};
  padding: 0px 0px 0px 8px;
  margin: 0px 0px 4px 0px;
}
&.depth-0.with-child {
  background-image: url(${MainNodeWithChildSvg});
  background-repeat: no-repeat;
}
&:last-child {
  background: none;
  border-left: none;
}
&:last-child:not(.depth-0) {
  background-image: url(${LastNodeSvg});
  background-repeat: no-repeat;
}
&:last-child:not(.depth-0).with-child {
  background-image: url(${LastNodeWithChildSvg});
  background-repeat: no-repeat;
}
&.hovered, &.opened {
  background-color: #eeeeee;
}
`;
const HeaderRow = styled.div`
position: relative;
display: flex;
align-items: center;
width: 100%;
height: 32px;
padding: 6px 0px;

font-size: 14px;
font-weight: 400;
line-height: 20px;
color: rgba(0, 0, 0, .5);

cursor: pointer;

&.visible {
  font-weight: 500;
  color: #333333;
}
`;

const RowIcon = styled.div<{color?: string}>`
width: 10px;
height: 10px;
margin: 0px 8px 0px 3px;
flex-shrink: 0;

border: 1px solid #000000;
border-radius: 50%;

overflow: hidden;

background-color: ${({color}) => color || '#107CC7'};

.visible > & {
  width: 16px;
  height: 16px;
  margin-left: 0px;
}

& > svg {
  float: left;
}
.invisible > & > svg {
  display: none;
}
`;

const NoneOperationIcon = styled.div`
width: 2px;
height: 2px;

margin: 6px;
border-radius: 2px;

background-color: white;
.depth-0 > .visible & {
  background-color: #666666;
}
`;

const RowContent = styled.div`
flex: 1 0 0px;
width: 0px;
height: 20px;
padding: 0px 8px 0px 0px;

white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
`;


interface FeatureRowProps {
  feature: DrillFeature;
}

const FeatureRow = ({feature}: FeatureRowProps) => {
  const {color, bgColor, depth, isVisible} = feature.drillInfo

  return (
    <RowWrapper
      className={[
        `depth-${depth}`,
        (isVisible || _.isEmpty(feature.operands)) ? '' : 'with-child',
      ].join(' ')}
      bgColor={bgColor}
    >
      <HeaderRow className={isVisible ? 'visible' : 'invisible'}>
        <RowIcon color={
          depth === 0 ? (isVisible ? '#107CC7' : '#ffffff') : color
        }>
          <OperationIcon operation={feature.operation}/>
        </RowIcon>
        <RowContent>
          {feature.name}
        </RowContent>
      </HeaderRow>
      {!feature.drillInfo.isVisible && _.map(feature.operands, (operand) => {
        return (
          <FeatureRow
            feature={operand}
            key={operand.id}/>             
        )
      })}
    </RowWrapper>
  )
}

const TreeView = () => {
  const drillDownFeatures = useRecoilValue<DrillFeature[]>(drillFeaturesState)
  const orderedFeatures = _.sortBy(drillDownFeatures,
    ['chart.gridInfo.y', 'chart.gridInfo.x']);

  return (
    <Wrapper>
      {_.map(orderedFeatures, (feature) => {
        return <FeatureRow feature={feature} key={feature.id}/>
      })}
    </Wrapper>
  )

};

export default TreeView;
