import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import _ from 'lodash';

import React, { useCallback, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import LastNodeSvg from '../../assets/last-node.svg';
import LastNodeWithChildSvg from '../../assets/last-node-with-child.svg';
import MainNodeWithChildSvg from '../../assets/main-node-with-child.svg';
import MiddleNodeSvg from '../../assets/middle-node.svg';
import MiddleNodeWithChildSvg from '../../assets/middle-node-with-child.svg';

import TreeMenuIconSvg from '../../assets/tree-menu-icon.svg';

import OperationIcon from '../common/OperationIcon';

import { Feature, Operation, } from '../../models/feature';
import { FeatureOperation, useFeatureManager } from '../../utils/hooks/useFeatureManager';

const Wrapper = styled.div`
width: 100%;
padding: 0px 12px 12px 8px;
`;

const RowWrapper = styled.div`
position: relative;
padding: 0px 0px 0px 16px;

background-image: url(${MiddleNodeSvg});
background-repeat: repeat-y;

&.with-child {
  background-image: url(${MiddleNodeWithChildSvg});
  background-repeat: no-repeat;
}

border-radius: 8px;

&.depth-0 {
  background: none;
  padding: 0px 0px 0px 8px;
}
&.depth-0.with-child {
  background-image: url(${MainNodeWithChildSvg});
  background-repeat: no-repeat;
}
&:last-child {
  background: none;
  border-left: none;
}
&:last-child:not(.depth-0) {
  background-image: url(${LastNodeSvg});
  background-repeat: no-repeat;
}
&:last-child:not(.depth-0).with-child {
  background-image: url(${LastNodeWithChildSvg});
  background-repeat: no-repeat;
}
&.hovered, &.opened {
  background-color: #eeeeee;
}

&:hover {
  background-color: rgba(67, 160, 71, 0.075);
}
`;
const HeaderRow = styled.div`
position: relative;
display: flex;
align-items: center;
width: 100%;
height: 36px;
padding: 8px 0px;

font-size: 14px;
font-weight: 400;
line-height: 20px;
color: #333333;

cursor: pointer;

*:hover > &, *.opened > & {
  font-weight: 600;
}
`;

const RowIcon = styled.div`
width: 8px;
height: 8px;
margin: 0px 8px 0px 0px;
flex-shrink: 0;

border: 1px solid #000000;
border-radius: 50%;

overflow: hidden;

& > svg {
  float: left;
}
`;

const RowContent = styled.div`
flex: 1 0 0px;
width: 0px;
height: 20px;
padding: 0px 8px 0px 0px;

white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
`;

const MenuIcon = styled.div`
position: absolute;
top: 6px;
right: 4px;
width: 24px;
height: 24px;

border-radius: 24px;
background-image: url(${TreeMenuIconSvg});

display: none;

*:hover > &, *.opened > * > & {
  display: block;
}`;


interface FeatureRowProps {
  feature: Feature;
  depth: number;
}

const colorList = ['#107CC7', '#78A639', '#AE8452']

const FeatureRow = ({feature, depth}: FeatureRowProps) => {
  const [applyOperationToFeature] = useFeatureManager();
  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null)
  const ref = useRef<HTMLDivElement>(null);

  const menuIsOpened = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const splitIt = useCallback((operand: Feature) => {
    applyOperationToFeature(FeatureOperation.SPLIT, [operand.id])
  }, [feature, applyOperationToFeature]);

  return (
    <RowWrapper
      className={[
        `depth-${depth}`,
        _.isEmpty(feature.operands) ? '' : 'with-child',
        menuIsOpened ? 'opened' : '',
      ].join(' ')}
    >
      <HeaderRow>
        <RowIcon
          style={{
            width: _.isEmpty(feature.operands) ? '10px' : '16px',
            height: _.isEmpty(feature.operands) ? '10px' : '16px',
            marginLeft: _.isEmpty(feature.operands) ? '3px' : '0px',
            backgroundColor: colorList[depth % 3]
          }}
        >
          <OperationIcon operation={feature.operation} margin={3}/>
        </RowIcon>
        <RowContent>
          {feature.name}
        </RowContent>
        {
          depth === 0 && feature.operands.length > 0 &&
          <React.Fragment>
            <MenuIcon onClick={handleClick} ref={ref}/>
            <Menu open={menuIsOpened}
                  anchorEl={anchorEl}
                  onClose={handleClose}>
              <MenuItem onClick={() => splitIt(feature)}>Split</MenuItem>
            </Menu>
          </React.Fragment>
        }
      </HeaderRow>
        {_.map(feature.operands, (operand) => {
          return (
            <FeatureRow
              feature={operand}
              depth={depth + 1}
              key={operand.id}/>             
          )
        })}
    </RowWrapper>
  )
}

interface TreeViewProps {
  features: Feature[]
}

const TreeView = ({features}: TreeViewProps) => {

  const orderedFeatures = _.sortBy(features,
                                   ['chart.gridInfo.y', 'chart.gridInfo.x']);
  return (
    <Wrapper>
      {_.map(orderedFeatures, (feature) => {
        return <FeatureRow feature={feature} depth={0} key={feature.id}/>
      })}
    </Wrapper>
  )

};

export default TreeView;
