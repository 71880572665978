import styled from '@emotion/styled';
import { blue, green, grey } from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';

import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Feature } from '../../models/feature';
import { selectedFeatureIdsState } from '../../recoils/feature';
import { FeatureOperation, useFeatureManager } from '../../utils/hooks/useFeatureManager';

import {ReactComponent as TreeHideIconSvg} from '../../assets/tree-hide-icon.svg';
import {ReactComponent as TreePlusIconSvg} from '../../assets/tree-plus-icon.svg';
import {ReactComponent as TreeMinusIconSvg} from '../../assets/tree-minus-icon.svg';
import {ReactComponent as TreeDivideIconSvg} from '../../assets/tree-divide-icon.svg';
import {ReactComponent as TreeMeanIconSvg} from '../../assets/tree-mean-icon.svg';
import {ReactComponent as TreeMergeIconSvg} from '../../assets/tree-merge-icon.svg';
import {ReactComponent as TreeMerge2yIconSvg} from '../../assets/tree-merge-2y-icon.svg';
import {ReactComponent as TreeScatterIconSvg} from '../../assets/tree-scatter-icon.svg';

import {MemoizedChart} from '../../components/charts/Chart';

const SectionWrapper = styled.div`
display: flex;
width: 100%;

height: 60px;
flex: 0 0 60px;

padding: 0px 8px 0px;

transition: height .3s, flex .3s;
`;

const Container = styled.div`
display: flex;
flex-direction: column;
flex: 1 0 0px;
width: 100%;
padding: 8px 12px;

border-radius: 8px 8px 0px 0px;

background-color: #ffffff;
box-shadow: 0px 0px 3px rgba(0,0,0,.3);

overflow: hidden;

z-index: 10;
`;

const ButtonRow = styled.div`
display: flex;
width: 100%;
margin-top: 4px;
`;

const ActionButton = styled.button`
display: flex;
padding: 4px 4px 4px 12px;
margin: 0px 12px 0px 0px;

font-size: 14px;
font-weight: 300;
line-height: 28px;
color: #333333;

border: none;
border-radius: 4px;

color: #ffffff;

background-color: #bbbbbb;

transition: background-color .1s;
cursor: not-allowed;

&.available {
  background-color: #666666;
  box-shadow: 0px 1px 1px rgba(0,0,0,.1);
  cursor: pointer;
}
&.selected {
  background-color: ${green[600]};
  box-shadow: 0px 1px 1px rgba(0,0,0,.1);
  cursor: pointer;
}

`;

const ActionIcon = styled.div`
width: 28px;
height: 28px;
margin: 0px 0px 0px 12px;
border-radius: 4px;

background-color: white;

& svg {
  width: 28px;
  height: 28px;
}
& path {
  fill: ${grey[600]} !important;
}
`

const ClearButton = styled.button`
padding: 4px 12px;
margin: 0px 0px 0px auto;

font-size: 16px;
font-weight: 500;
line-height: 24px;
color: ${green[600]};

border: none;
border-radius: 4px;

background-color: transparent;

transition: background-color .1s;
cursor: pointer;

&:hover {
  background-color: ${green[50]};
}
`;

const PreviewTitleRow = styled.div`
display: flex;
width: 100%;
heigth: 32px;
flex: 0 0 32px;

padding: 4px 0px;

font-size: 16px;
font-weight: 500;
line-height: 24px;

color: #333333;

& > b {
  margin: 0px 4px;
  font-size: 16px;
  line-height: 24px;
  color: ${green[600]};
}
`;

const PreviewSection = styled.div`
display: flex;
flex: 0 0 304px;
height: 304px;
min-height: 0px;
overflow: hidden;
padding: 8px 0px 0px 0px;
`;

const ChartPreviewSection = styled.div`
flex: 0 0 400px;
width: 400px;
height: 100%;
margin: 0px 8px 0px 0px;
`;

const FeatureEditingSection = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0px;
width: 100%;
height: 100%;
`;

const FeatureEditingRow = styled.div`
display: flex;
width: 100%;
align-items: flex-start;
`

const FeatureEditingRowTitle = styled.div`
flex: 0 0 80px;
width: 80px;
height: 40px;

padding: 8px 0px;
margin: 0px 8px 0px 0px;

font-size: 14px;
font-weight: 400;
line-height: 24px;
color: #333333;
`;

const FeatureEditingRowContent = styled.div`
flex: 1 0 0px;
width: 100%;

padding: 4px 0px;

`;

const FeatureNameInput = styled(OutlinedInput)`
width: 100%;
height: 32px;
font-size: 14px;
font-weight: 400;
line-height: 24px;

& > .MuiInputBase-input {
  box-sizing: border-box;
  padding: 3px 7px;
}

&:hover:not(.Mui-focused) > .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0,0,0,.5);
}
`

const FeatureDescriptionInput = styled(OutlinedInput)`
width: 100%;
padding: 0px;
font-size: 14px;
font-weight: 400;
line-height: 24px;

& > .MuiInputBase-input {
  box-sizing: border-box;
  padding: 3px 7px;
}

&:hover:not(.Mui-focused) > .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0,0,0,.5);
}
`

const FeatureApplyButton = styled.button`
width: 108px;

height: 40px;

padding: 8px 12px;
margin: auto 0px 0px auto;

font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #ffffff;

border: none;
border-radius: 4px;

cursor: not-allowed;

background-color: #999999;

&.activated {
  cursor: pointer;
  background-color: ${green[600]};
}
`

const EquationRow = styled.div`
display: flex;
width: 100%;
height: 32px;

padding: 4px 0px;

`

const EquationContent = styled.div`
font-size: 15px;
font-weight: 400;
line-height: 24px;

color: #333333;
`


const FeatureName = styled.div`
display: inline-block;
margin: 0px 2px;
font-size: 18px;
font-weight: 500;
line-height: 20px;
color: #333333;

& > small {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px 0px;

  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  vertical-align: top;
  color: white;

  background-color: ${green[600]};
  border-radius: 16px;
}`


const FeatureInfix = styled.div`
display: inline-block;
margin: 0px;
font-size: 16px;
font-weight: 400;
line-height: 20px;
color: #333333;
&:last-child {
  display: none;
}`;

interface _Action {
  name: string
  description: string
  op: FeatureOperation
  limit: number
  prefix: string
  infix: string
  postfix: string
  hasDataDimLimit: boolean
  svgIcon: React.ReactNode
}

const actions: _Action[] = [
  {
    name: 'Add',
    description: '선택된 데이터들을 x 축 기준으로 더함',
    op: FeatureOperation.ADD,
    limit: 20,
    prefix: '',
    postfix: '',
    infix: '+',
    hasDataDimLimit: true,
    svgIcon: <TreePlusIconSvg/>,
  },
  {
    name: 'Subtract',
    description: '선택된 데이터들을 x 축 기준으로 뺌',
    op: FeatureOperation.SUBTRACT,
    limit: 20,
    prefix: '',
    postfix: '',
    infix: '-',
    hasDataDimLimit: true,
    svgIcon: <TreeMinusIconSvg/>,
  },
  {
    name: 'Divide',
    description: '두 데이터를 x축을 기준으로 나눔',
    op: FeatureOperation.DIVIDE,
    limit: 2,
    prefix: '',
    postfix: '',
    infix: '/',
    hasDataDimLimit: true,
    svgIcon: <TreeDivideIconSvg/>,
  },
  {
    name: 'Average',
    description: '선택된 데이터를 x축을 기준으로 평균냄',
    op: FeatureOperation.MEAN,
    limit: 20,
    prefix: '(',
    postfix: ') / {count}',
    infix: '+',
    hasDataDimLimit: true,
    svgIcon: <TreeMeanIconSvg/>,
  },
  {
    name: 'Merge (1 Y-axis)',
    description: '선택된 데이터를 x축을 기준으로 평균냄',
    op: FeatureOperation.MERGE_1_AXIS, 
    limit: 10,
    prefix: '',
    postfix: ' in one chart.',
    infix: ',',
    hasDataDimLimit: true,
    svgIcon: <TreeMergeIconSvg/>,
  },
  {
    name: 'Merge (2 Y-axis)',
    description: '선택된 데이터를 x축을 기준으로 평균냄',
    op: FeatureOperation.MERGE_2_AXIS, 
    limit: 2,
    prefix: '',
    postfix: ' in one chart.',
    infix: ',',
    hasDataDimLimit: true,
    svgIcon: <TreeMerge2yIconSvg/>,
  },
  {
    name: 'Scatter',
    description: '두 데이터를 사용하여',
    op: FeatureOperation.SCATTER,
    limit: 2,
    prefix: 'Switch the chart type - ',
    postfix: '',
    infix: ',',
    hasDataDimLimit: true,
    svgIcon: <TreeScatterIconSvg/>,
  },
  {
    name: 'Stack',
    description: '선택된 데이터를 하나로 표기함',
    op: FeatureOperation.HIDE, 
    limit: 20,
    prefix: 'Aggregate - ',
    postfix: '',
    infix: ',',
    hasDataDimLimit: false,
    svgIcon: <TreeHideIconSvg/>,
  },
]

interface OperationSectionProps {
  features: Feature[]
}

const generateOperationDescription = (
  action: _Action | undefined, selFeatIds: string[], features: Feature[],
): React.ReactNode => {
  if (_.isUndefined(action)) {
    return null
  }
  const selNodes = _.map(selFeatIds, (selFeatId: string, idx: number) => {
    const alphabet = String.fromCharCode(idx + 65);
    const feature = _.find(features, {id: selFeatId});
    if (_.isUndefined(feature)) {
      return null
    }

    return (
      <React.Fragment key={idx}>
        <FeatureName>
          <small>{alphabet}</small>
        </FeatureName>
        <FeatureInfix>{action.infix}</FeatureInfix>
      </React.Fragment>
    )
  });

  return (
    <React.Fragment>
      <EquationRow>
        <EquationContent>
          {action.prefix}
          {selNodes}
          {action.postfix.replace('{count}', `${selFeatIds.length}`)}
        </EquationContent>
      </EquationRow>
    </React.Fragment>
  );
}

const actionIsAvailable = (
  action: _Action,
  selFeatIds: string[],
  features: Feature[],
): boolean => {
  if (selFeatIds.length <= 1) return false
  if (selFeatIds.length > action.limit) return false

  const operandHasMultiColumnData = _.some(features, (feature) => {
    if (!_.includes(selFeatIds, feature.id)) return false
    return feature.chart.data.length >= 2
  })

  if (action.hasDataDimLimit && operandHasMultiColumnData) return false
  return true
}

const OperationSection = ({features}: OperationSectionProps) => {
  const [
    applyOperationToFeature,
    applyOperationTemporally,
  ] = useFeatureManager();
  const [selFeatIds, setSelFeatIds] = useRecoilState<string[]>(selectedFeatureIdsState);
  const [op, setOp] = useState<_Action|undefined>(undefined);

  const clearSelectedFeatures = useCallback(() => {
    setSelFeatIds([])
    setOp(undefined)
  }, [selFeatIds, setSelFeatIds])

  const [previewFeature, setPreviewFeature] = useState<Feature|undefined>(undefined)
  const [featureName, setFeatureName] = useState<string>('')
  const [featureDescription, setFeatureDescription] = useState<string>('')

  const newFeatureProps = {name: featureName, description: featureDescription}

  useEffect(() => {
    if (_.isUndefined(op)) {
      return setPreviewFeature(undefined)
    }
    if (!actionIsAvailable(op, selFeatIds, features)) {
      setOp(undefined)
      setPreviewFeature(undefined)
    } else {
      setPreviewFeature(applyOperationTemporally(op.op, selFeatIds, newFeatureProps))
    }
  }, [selFeatIds, setSelFeatIds, op, setOp, featureName, featureDescription])

  const applyIsActivated = (
    featureName && featureDescription && !_.isUndefined(op))

  const applyOperation = () => {
    if (!applyIsActivated) return

    applyOperationToFeature(op.op, selFeatIds, newFeatureProps)
    setFeatureDescription('')
    setFeatureName('')
  }

  const sectionHeight = _.isUndefined(op) ? 60 : 360;

  return (
    <SectionWrapper style={{
      height: `${sectionHeight}px`,
      flex: `0 0 ${sectionHeight}px`,
    }}>
      <Container>
        <ButtonRow>
          {actions.map((action, idx) => {
            const isAvailable = actionIsAvailable(action, selFeatIds, features)
            return (
              <ActionButton key={idx}
                            className={[
                              isAvailable ? 'available' : '',
                              op === action ? 'selected' : ''
                            ].join(' ')}
                            onClick={() => { if(isAvailable) setOp(action) }}>
                {action.name}
                <ActionIcon>
                  {action.svgIcon}
                </ActionIcon>
              </ActionButton>
            )
          })}
          {selFeatIds.length > 0 &&
            <ClearButton onClick={clearSelectedFeatures}>
              Clear ({selFeatIds.length})
            </ClearButton>
          }
        </ButtonRow>
        <PreviewSection>
          <ChartPreviewSection>
            {
              !_.isUndefined(previewFeature) &&
              <MemoizedChart feature={previewFeature}
                             width={400}
                             height={296}
                             yAxisWidth={40}/>
            }
          </ChartPreviewSection>
          <FeatureEditingSection>
            <PreviewTitleRow>
              Preview for {op && <b>{op.name.toUpperCase()}</b>} Operation
            </PreviewTitleRow>
            <FeatureEditingRow>
              <FeatureEditingRowTitle>Equation</FeatureEditingRowTitle>
              <FeatureEditingRowContent>
                {generateOperationDescription(op, selFeatIds, features)}
              </FeatureEditingRowContent>
            </FeatureEditingRow>
            <FeatureEditingRow>
              <FeatureEditingRowTitle>Name</FeatureEditingRowTitle>
              <FeatureEditingRowContent>
                <FeatureNameInput placeholder="Chart name, ex. xxxxx"
                                  value={featureName}
                                  onChange={(evt) => setFeatureName(evt.target.value)}/>
              </FeatureEditingRowContent>
            </FeatureEditingRow>
            <FeatureEditingRow>
              <FeatureEditingRowTitle>Description</FeatureEditingRowTitle>
              <FeatureEditingRowContent>
                <FeatureDescriptionInput rows={5}
                                         multiline
                                         placeholder="Description of the chart"
                                         value={featureDescription}
                                         onChange={(evt) => setFeatureDescription(evt.target.value)}/>
              </FeatureEditingRowContent>
            </FeatureEditingRow>
            <FeatureApplyButton className={applyIsActivated ? 'activated' : ''}
                                onClick={applyOperation}>
              Apply
            </FeatureApplyButton>
          </FeatureEditingSection>
        </PreviewSection>
      </Container>
    </SectionWrapper>
  )

};

export default OperationSection;
