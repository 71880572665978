import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';

import React from 'react';

import LeftChevronIconSvgUrl from '../../assets/left-chevron-icon.svg';
import { useNavigate } from 'react-router-dom';

const Section = styled.div`
display: flex;
width: 100%;
height: 65px;
flex-direction: row;
align-items: center;

border-bottom: 1px solid #cccccc;
`;

const GoAuthorPageButton = styled.button`
width: 32px;
height: 32px;

margin: 16px;

border: none;
border-radius: 50%;
background-color: ${blue[600]};
background-image: url(${LeftChevronIconSvgUrl});

cursor: pointer;
&:hover {
  box-shadow: 0px 1px 4px rgba(0,0,0,.15);
}
`;

const Text = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;

  color: #333333;
`;

const TitleSection = () => {
  const navigate = useNavigate();
  return (
    <Section>
      <GoAuthorPageButton onClick={() => navigate("/")}/>
      <Text>Add new charts</Text>
    </Section>
  );
};

export default TitleSection;
