import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PageLayout from './Layout';

import AuthorPage from './AuthorPage';
import ChartManagerPage from './ChartManagerPage';
import ReaderPage from './ReaderPage';

const router = createBrowserRouter([
  {
    path: "/chart-manager",
    element: <PageLayout><ChartManagerPage/></PageLayout>,
  },
  {
    path: "/reader",
    element: <PageLayout><ReaderPage/></PageLayout>,
  },
  {
    path: "/",
    element: <PageLayout><AuthorPage/></PageLayout>,
  },
]);

const Router = () => {
  return (
    <React.Fragment>
      <RouterProvider router={router}/>
    </React.Fragment>
  )
};

export default Router;
