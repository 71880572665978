import styled from '@emotion/styled';
import React from 'react';
import { useRecoilState } from 'recoil';

import { Feature } from '../../models/feature';
import { featuresState } from '../../recoils/feature';

import ChartSection from '../../components/charts/ChartSection';
import TreeView from '../../components/treeview/TreeView';

import HeaderSection from './HeaderSection';
import OperationSection from './OperationSection';
import Scrollbars from 'react-custom-scrollbars-2';

const SectionWrapper = styled.div`
display: flex;
width: 100%;
`;

const SidebarSection = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
flex: 1 0 0px;
`;

const NoFeatureTree = styled.div`
padding: 0px 12px;
font-size: 14px;
font-weight: 400;
line-height: 28px;
text-align: left;
color: rgba(0,0,0,.5);
`;

const ContentSection = styled.div`
flex: 0 0 1272px;

display: flex;
flex-direction: column;

padding: 0px 0px 0px 0px;

border-radius: 0px 0px 0px 0px;
background-color: #f0f0f0;
`;

const ChartSectionWrapper = styled.div`
position: relative;
flex: 1 0 0px;

&::before {
  position: absolute;
  display: block;
  content: '';
  top: 0px;
  left: 12px;
  width: calc(100% - 24px);
  height: 6px;
  background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(240,240,240,0) 100%);

  z-index: 10;
}
`;

const GuideForAuthorPageWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

flex: 1 0 0px;
width: 100%;
height: 100%;
`;

const GuideForAuthorPageTitle = styled.div`
margin: 0px 0px 8px 0px;
font-size: 24px;
font-weight: 500;
line-height: 36px;
text-align: center;
color: rgba(0,0,0,.6);
`;

const GuideForAuthorPageContent = styled.div`
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: center;
color: rgba(0,0,0,.5);

& > b {
  font-weight: 500;
  color: rgba(0,0,0,.6);
}
`


const AuthorPage = () => {
  const [features, setFeatures] = useRecoilState<Feature[]>(featuresState);

  return (
    <SectionWrapper>
      <SidebarSection>
        <HeaderSection/>
        {
          features.length > 0 ?
          <Scrollbars>
            <TreeView features={features}/>
          </Scrollbars> :
          <NoFeatureTree>
            Features not found.
          </NoFeatureTree>
        }
      </SidebarSection>
      <ContentSection>
        <ChartSectionWrapper>
          {
            features.length > 0 ?
            <Scrollbars>
              <ChartSection
                features={features}
                setFeatures={setFeatures}
                isEditable={true}
              />
            </Scrollbars> :
            <GuideForAuthorPageWrapper>
              <GuideForAuthorPageTitle>No charts found.</GuideForAuthorPageTitle>
              <GuideForAuthorPageContent>
                Please click on the <b>"Add new charts"</b> button<br/>
                at the top left corner to add charts for Drillboards.
                <br/><br/>
                After you make a board successfully,<br/>
                press <b>"Upload"</b> button and save your board.
              </GuideForAuthorPageContent>
            </GuideForAuthorPageWrapper>
          }
        </ChartSectionWrapper>
        <OperationSection features={features}/>
      </ContentSection>
    </SectionWrapper>
  )
};

export default AuthorPage;
