import React, {useRef} from 'react';

import InputList from './InputList';

import {ReactComponent as CloseIconSvg} from '../../../assets/close-icon.svg';


const SearchComponent = (props) => {

  const target = useRef(null);

  const onCancelClick = () => {
    props.onCancelClick(props.index);
  }

  return(
    <div className="search_value_wrapper" ref={target}>
      <span className="search-label">{props.data.label}</span>
      <InputList
        data={props.data}
        onUpdateFilter={props.onUpdateFilter}
        wrapperDom={target}
      />
      <span className="value_close" onMouseDown={onCancelClick}>
        <CloseIconSvg/>
      </span>
    </div>
  )
}


export default SearchComponent;
