import React, { useState } from 'react';

import styled from '@emotion/styled';

import Button from '@mui/material/Button';

import { ReactComponent as PlusIconSvg } from '../../assets/plus-icon.svg';
import { ReactComponent as ExportIconSvg } from '../../assets/export-icon.svg';

import { featuresState } from '../../recoils/feature';
import { useRecoilValue } from 'recoil';
import { Feature } from '../../models/feature';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import BoardUploadModal from './BoardUploadModal';


const Section = styled.div`
display: flex;
flex-direction: column;

width: 100%;

padding: 12px 16px 12px 12px;
`;

const ChartDescription = styled.div`
width: 100%;

margin: 12px 0px 0px 0px;
padding: 0px 0px;

font-size: 14px;
font-weight: 400;
line-height: 16px;
color: #666666;

& > b {
  font-size: 15px;
  line-height: 16px;
  color: #333333;
}
`;

const ToolButtonSection = styled.div`
display: flex;
flex: 0 0 0px;
height: 40px;
`;


const AddChartButton = styled(Button)`
display: flex;
justify-content: center;

height: 40px;

padding: 10px 15px 8px 11px;
margin: 0px 0px 0px 0px;

font-size: 14px;
line-height: 20px;

border-radius: 4px;

text-wrap: nowrap;

background-color: transparent;

cursor: pointer;

& > svg {
  margin: 0px 8px 2px 0px;
}
`;

const ToolSeparator = styled.div`
width: 1px;
height: 24px;
margin: 8px 12px;

background-color: #cccccc;
`;

const SaveButton = styled(Button)`
display: flex;
min-width: 40px;
height: 40px;
padding: 9px 12px 7px;

color: white;

&.Mui-disabled {
  color: white;
  background-color: rgba(0,0,0,.2);
}

& > svg {
  width: 16px;
  height: 16px;
  margin: 0px 4px 2px 0px;
}
`;

const countChart = (features: Feature[]): number => {
  if (!features.length) {
    return 0
  }

  return _.sum(_.map(features, (feature) => {
    if (_.isUndefined(feature.operands)) {
      return 1
    }
    return 1 + countChart(feature.operands)
  }))
}

const HeaderSection = () => {
  const features = useRecoilValue<Feature[]>(featuresState);
  const [chartName, setChartName] = useState<string>('');

  const [open, setOpen] = useState<boolean>(false)

  const rootChartCount = features.length
  const totalChartCount = countChart(features)

  const updateChartNameAndWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChartName(event.target.value)
    event.target.style.width = `${event.target.scrollWidth}px`
  }
  const navigate = useNavigate()

  return (
    <Section>
      <ToolButtonSection>
        {/* <BoardUploadModal features={features}
                          open={open}
                          onClose={() => setOpen(false)}/> */}
        <SaveButton variant="contained"
                    disableElevation
                    // onClick={() => setOpen(true)}
                    disabled={true}>
          <ExportIconSvg/> Upload
        </SaveButton>
        <ToolSeparator/>
        <AddChartButton variant="outlined"
                        style={{whiteSpace: 'nowrap'}}
                        onClick={() => navigate('/chart-manager')}>
          <PlusIconSvg/>
          Add new charts
        </AddChartButton>
      </ToolButtonSection>
      <ChartDescription>
        <b>{rootChartCount}</b> chart(s)
        consists of <b>{totalChartCount}</b> chart(s)
      </ChartDescription>
    </Section>
  );
};

export default HeaderSection;
