import styled from '@emotion/styled';
import { green } from '@mui/material/colors';

import React from 'react';

import TitleSection from './TitleSection';
import FilterSection from './FilterSection';
import ChartSection from './ChartSection';

const ChartManagerPageWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`;



const ChartManagerPage = () => {

  return (
    <ChartManagerPageWrapper>
      <TitleSection/>
      <FilterSection/>
      <ChartSection/>
    </ChartManagerPageWrapper>
  )
};

export default ChartManagerPage;
