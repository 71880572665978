
export enum ChartType {
    BAR,
    LINE,
    SCATTER,
}

export enum Operation {
    ADD = 0,
    SUBTRACT = 1,
    MEAN = 2,
    DIVIDE = 7,
    SCATTER = 3,
    MERGE_1_AXIS = 4,
    MERGE_2_AXIS = 5,
    HIDE = 6,
    // Next op: 8
}

export interface GridInfo {
    x: number;
    y: number;
    w: number;
    h: number;
}

export interface FeatureChart {
    type: ChartType;
    data: Array<Array<number>>;
    ticks: Array<string>;
    labels: Array<string>;
    yAxes: Array<'left' | 'right'>;
    gridInfo: GridInfo;
}

export type FeatureAttribute = Object;

export interface DrillInfo {
    depth: number;
    color?: string;
    bgColor?: string;
    isVisible: boolean;
    path: string;
}

export interface Feature {
    id: string;
    name: string;
    description: string;
    attribute: any;
    operation?: Operation;
    operands: Array<Feature>;
    chart: FeatureChart;
}

export interface DrillFeature extends Feature {
    drillInfo: DrillInfo;
    operands: Array<DrillFeature>;
}

export interface ChartFilter {
    name: string;
    value: string;
}

export interface Board {
    name: string
    author: string
    features: Feature[]
}
