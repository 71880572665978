import styled from "@emotion/styled";

import React from "react";
import { useLocation } from "react-router-dom";


import Header, { HeaderModeType } from '../components/common/Header';
import _ from "lodash";

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;

width: 100%;
height: 100%;
min-height: calc(100vh - 56px);

overflow-x: hidden;
`;

const Wrapper = styled.div`
display: flex;


flex: 1 0 0px;
width: 1600px;
height: 100%;

background-color: #ffffff;
box-shadow: 0px 0px 3px rgba(0, 0, 0, .3);
`;


interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout = ({children}: PageLayoutProps) => {
  const location = useLocation();
  const mode = location.pathname.startsWith('/reader') ? HeaderModeType.READER :
               HeaderModeType.AUTHOR;

  return (
    <React.Fragment>
      <Header mode={mode} />
      <Container>
        <Wrapper>
          {children}
        </Wrapper>
      </Container>
    </React.Fragment>
  )
};

export default PageLayout
