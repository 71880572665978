import React from 'react';
import { RecoilRoot } from 'recoil';

import Router from './pages/Router';

const App = () => {
  return (
    <RecoilRoot>
      <Router></Router>
    </RecoilRoot>
  )
};

export default App;
