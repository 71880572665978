import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const InputSearch = (props) => {
  const [showOption, setShowOption] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const hideOptions = () => {
    setShowOption(false);
  };

  const showOptions = () => {
    setShowOption(true);
  };

  const onInputChange = (e) => {
    setInputSearchValue(e.target.value);
  }

  const onOptionClick = (option) => {
    props.onOptionClick(option)
    setShowOption(false)
  }

  return (
    <React.Fragment>
      <input className="search_input"
             key="search_input"
             onFocus={showOptions}
             value={inputSearchValue}
             onChange={onInputChange}
             placeholder="Add filter"
      />
      {showOption &&
        <div className="options_wrapper">
          {
            props.category.map((option, index) => {
              const selectedIndex = _.find(props.selectedValue,
                                           {name: option.name});
              const alreadySelected = selectedIndex !== undefined;
              return (
                <span
                  key={index}
                  className={[
                    'option',
                    alreadySelected ? 'selected' : ''
                  ].join(' ')}
                  onClick={()=>{onOptionClick(option)}}
                >{option.label}</span>
              )
            })
          }
        </div>
      }
    </React.Fragment>
  )
};

export default InputSearch;
