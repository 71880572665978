type CMYK = [number, number, number, number]

const hex2Cmyk = (hex: string): CMYK => {
  let base = Number(`0x${hex.slice(1)}`)
  const b = (base % 256) / 255
  base = Math.floor(base / 256)
  const g = (base % 256) / 255
  const r = Math.floor(base / 256) / 255

  const k = 1 - Math.max(r, g, b)
  if (k === 1) {
    return [0, 0, 0, 1]
  }

  const c = (1 - r - k) / (1 - k)
  const m = (1 - g - k) / (1 - k)
  const y = (1 - b - k) / (1 - k)
  return [c, m, y, k]
}

const cmyk2Hex = ([c, m, y, k]: CMYK): string => {
  const r = Math.round(255 * (1 - c) * (1 - k))
  const g = Math.round(255 * (1 - m) * (1 - k))
  const b = Math.round(255 * (1 - y) * (1 - k))
  return '#' + (((r * 256) + g) * 256 + b).toString(16).padStart(6, '0')
}

const colorChannelMixer = (
  baseColorChannel: number,
  targetColorChannel: number,
  ratio: number,
): number => {
  return targetColorChannel * ratio + baseColorChannel * (1 - ratio)
}

const colorMixer = (
  baseRgb: string,
  targetRgb: string,
  ratio: number,
): string => {
  const baseCmyk = hex2Cmyk(baseRgb)
  const targetCmyk = hex2Cmyk(targetRgb)

  const c = colorChannelMixer(baseCmyk[0], targetCmyk[0], ratio)
  const m = colorChannelMixer(baseCmyk[1], targetCmyk[1], ratio)
  const y = colorChannelMixer(baseCmyk[2], targetCmyk[2], ratio)
  const k = colorChannelMixer(baseCmyk[3], targetCmyk[3], ratio)
  return cmyk2Hex([c, m, y, k])
}

export {colorMixer}

