import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import InputSearch from './InputSearch';
import SearchComponent from './SearchComponent';

import '../css/visual_search.css';


const VisualSearch = (props) => {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    setSelectedValue([])
  }, [props.category])

  const onOptionClick = (option) => {
    selectedValue.push(option);
    setSelectedValue(_.cloneDeep(selectedValue));
  }

  const onCancelClick = (index) => {
    selectedValue.splice(index, 1);
    setSelectedValue(_.cloneDeep(selectedValue));
  }

  const onUpdateFilter = (newFilter, index) => {
    selectedValue[index] = _.cloneDeep({
      ...selectedValue[index],
      name: newFilter.name,
      value: newFilter.value
    });
    setSelectedValue(_.cloneDeep(selectedValue));
  }

  useEffect(() => {
    const filteredSelectedValue = _.filter(selectedValue, (option) => {
      return _.isString(option.value);
    });
    props.onFilter(_.cloneDeep(filteredSelectedValue));
  }, [selectedValue, setSelectedValue]);

  return (
    <div className={[
      'visual_search',
      props.className || ''
    ].join(" ")}>
      <div className="visual_search_wrapper clearfix">
        {
          selectedValue.map((value, index)=>{
            return (
              <SearchComponent
                data={value}
                index={index}
                key={index}
                onCancelClick={() => onCancelClick(index)}
                onUpdateFilter={(newFilter) => onUpdateFilter(newFilter, index)}
              />
            )
          })
        }
        <div className="visual_input_wrapper">
          <InputSearch
            category={props.category}
            selectedValue={selectedValue}
            onOptionClick={onOptionClick}/>
        </div>
      </div>
    </div>
 );
};

export default VisualSearch;
